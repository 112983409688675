/**
  * $name 10. Accent color
  * $type color
  */
/**
* $name 10. Font family
* $type text
*/
/**
* $name 30. Page background color
* $type color
*/
/**
* $name 31. Component background color
* $type color
*/
/**
* $name 90. Hover state background color
* $type color
*/
/**
* $name 120. Active state background color
* $type color
*/
/**
* $name 130. Selected state background color
* $type color
*/
/**
* $name 40. Border color
* $type color
*/
/**
* $name 41. Border color accessible
* $type color
*/
/**
* $name 50. Success color
* $type color
*/
/**
* $name 60. Warning color
* $type color
*/
/**
* $name 70. Danger color
* $type color
*/
/**
* $name 20. Text color
* $type color
*/
/**
* $name 20. Label color
* $type color
*/
/**
* $name 40. Icon color
* $type color
*/
/**
* $name 80. Hover state text color
* $type color
*/
/**
* $name 100. Focused state text color
* $type color
*/
/**
* $name 110. Focused state background color
* $type color
*/
/**
* $name 50. Arrow icon color
* $type color
*/
/**
* $name 30. Link color
* $type color
*/
:root {
  --dx-component-color-bg: rgb(41, 41, 41);
  --dx-color-main-bg: #1F1F1F;
  --dx-color-primary: rgb(71, 158, 245);
  --dx-color-danger: rgb(227, 125, 128);
  --dx-color-success: #54B054;
  --dx-color-warning: #fde300;
  --dx-color-border: rgb(97, 97, 97);
  --dx-color-text: rgb(255, 255, 255);
  --dx-color-icon: #adadad;
  --dx-color-spin-icon: #adadad;
  --dx-color-link: rgb(71, 158, 245);
  --dx-color-shadow: #000;
  --dx-color-separator: #525252;
}

/**
* $name 11. Button height
* $type text
*/
/**
* $name 12. Icon size
* $type text
*/
/**
* $name 13. Icon margin
* $type text
*/
/**
* $name 200. Border radius
* $type text
*/
:root {
  --dx-component-height: 32px;
  --dx-font-size: 14px;
  --dx-border-width: 1px;
  --dx-border-radius: 4px;
  --dx-font-size-icon: 20px;
}

:root {
  --dx-line-height: 1.428571;
}

:root {
  --icon-dark-warning-color: var(--warning-color);
  --card-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  --background-color: var(--base-bg);
  --icon-dark-warning-color: var(--dark-warning-color);
  --card-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.04);
  --background-color: var(--typography-bg);
}

:root {
  --dark-warning-color: #eaa300;
  --typography-bg: var(--base-bg);
  --typography-bg: var(--dx-color-main-bg);
  --side-panel-min-width: 48px;
  --content-padding: 24px;
  --page-padding: 32px;
  --header-toolbar-vertical-padding: 10px;
  --footer-height: 70px;
  --toolbar-vertical-padding: 16px;
  --toolbar-margin-bottom: var(--content-padding);
  --base-bg: var(--dx-component-color-bg);
  --base-bg-darken-5: #1c1c1c;
  --base-bg-darken-4: #1f1f1f;
  --side-panel-background: var(--base-bg-darken-5);
  --card-background: var(--base-bg);
  --calendar-width: 292px;
  --card-background: var(--base-bg-darken-5);
  --border-color: var(--dx-color-border);
  --accent-color: var(--dx-color-primary);
  --success-color: var(--dx-color-success);
  --info-color: var(--dx-color-primary);
  --warning-color: var(--dx-color-warning);
  --error-color: var(--dx-color-danger);
  --base-text-color: var(--dx-color-text);
  --background-gray-color: rgba(255, 255, 255, 0.04);
  --texteditor-edit-color: var(--dx-texteditor-color-text);
  --texteditor-label-color: var(--dx-texteditor-color-label);
  --base-text-color-alpha: rgba(255, 255, 255, 0.7);
  --toolbar-height: var(--dx-toolbar-height);
  --filled-texteditor-input-horizontal-padding: 12px;
  --list-padding-left: var(--dx-list-item-padding-inline);
  --list-padding-vertical: var(--dx-list-item-padding-block);
  --footer-left-padding: calc((var(--side-panel-min-width) - var(--dx-font-size-icon)) / 2);
  --popup-toolbar-item-spacing: var(--dx-popup-toolbar-item-padding-inline);
  --drop-down-gnrc-button-height: var(--dx-component-height);
  --theme-padding: var(--dx-button-padding-inline);
  --tooltip-danger-button-color: rgba(227, 125, 128, 0.1);
  --tooltip-success-button-color: rgba(84, 176, 84, 0.1);
  --subtitle-text-color: var(--texteditor-label-color);
  --button-height-theme-difference: calc((var(--theme-padding) - 10px) / 3);
  --button-height: calc(26px + var(--button-height-theme-difference));
  --scheduler-toolbar-height: calc(var(--button-height) + 2 * var(--theme-padding));
}